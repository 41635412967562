import { Head } from '@inertiajs/react';
import { useAppContext } from "../../contexts/app/AppContext";
import { usePage } from '@inertiajs/react';
import { useEffect } from 'react';

function MetaHead(seo) {

    //const { favicon } = useAppContext();
    const { favicon } = usePage().props

    useEffect(()=>{
        if(seo?.seo){
            document.title = seo?.seo['title'] ?? null;
            //document?.getElementsByTagName('meta')['description'].content = 'gsdg';
            document.querySelector('meta[name="description"]')?.setAttribute("content",seo?.seo['description'] ?? null);
            document.querySelector('meta[property="og:title"]')?.setAttribute("content",seo?.seo['title'] ?? null);
            document.querySelector('meta[property="og:description"]')?.setAttribute("content",seo?.seo['description'] ?? null);
        }
        
      },[])

    return (
        <>
            {seo.seo ?
                <Head>
                    <title head-key="title">{seo.seo['title']}</title>
                    <meta head-key="description" name="description" content={seo.seo['description']} />
                    { seo['metaKeywords'] && seo['metaKeywords'] != '' ? <meta head-key="keywords" name="keywords" content={seo.seo['metaKeywords']} /> : null } 
                    <link rel="icon" type="image/svg+xml" href={favicon} />
                    
                </Head>
            : 
                <Head>
                    <link rel="icon" type="image/svg+xml" href={favicon} />
                </Head>
       
            }
        </>
    )

}

export default MetaHead;